<template>
  <div
    class="accordion"
    :class="{
      'accordion--mobile-only': mobileOnly
    }"
  >
    <button
      v-if="showButton"
      class="accordion__button default-bold"
      :class="{
        'accordion__button--mobile-only': mobileOnly,
        'accordion__button--open': isOpen
      }"
      @click.prevent="buttonClickHandler"
      v-html="name"
    />
    <div
      class="accordion__content"
      :class="{
        'accordion__content--mobile-only': mobileOnly
      }"
      ref="content"
      :style="(() => {
        if (!initialOpen && !(mobileOnly && !isMobile)) {
          return 'height: 0;';
        }

        return '';
      })()"
    >
      <div
        class="accordion__content-inner"
        :class="{
          'html-teaser': isContentHtml
        }"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import {breakpoints} from '../../variables/variables.js';
import accordionMixin from "../../utilities/vueMixins/accordionMixin/accordionMixin";

export default {
  mixins: [
    accordionMixin
  ],
  props: {
    id: String,
    mobileOnly: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    initialOpen: {
      type: Boolean,
      default: false
    },
    isContentHtml: {
      type: Boolean,
      default: false
    },
    name: String
  },
  data() {
    return {
      isOpen: this.initialOpen,
      isMobile: window.innerWidth < breakpoints.tablet
    };
  },
  mounted() {
    this.currentAnimation = null;
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.tablet;
    }
  }
};
</script>
